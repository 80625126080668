import axios from "axios";

export const sendCaptchaVerification = async (token: string) => {
  const resp = await axios.post("/api/serverActions", { token });
  return resp.status === 200;
};

enum BLOCK_NAMES {
  CATEGORIES = "TestLayoutTestFlexLayoutFrontPageCategoriesLayout",
  HEROS = "TestLayoutTestFlexLayoutHeroArticlesLayout",
  HERO = "TestLayoutTestFlexLayoutFrontPageHeroArticleLayout",
  VIDEOS = "TestLayoutTestFlexLayoutFrontPageVideosLayout",
  PODCAST = "TestLayoutTestFlexLayoutFrontPagePodcastLayout",
  BLOKE = "TestLayoutTestFlexLayoutBlokeVideosLayout",
}

const NonRepeatableArticleContainers = [BLOCK_NAMES.HEROS];

interface findAllPostIdsFromObjectsProps {
  objs: object[];
  goodStrings?: string[];
  badStrings?: string[];
  array?: string[];
}

interface findAllPostIdsFromObjectProps {
  obj: object;
  goodStrings?: string[];
  badStrings?: string[];
  array: string[];
}

interface AllNodesProps {
  obj: object;
  keys: string[];
  array: string[];
}

function allNodes({ obj, keys, array }: AllNodesProps) {
  array = array || [];
  if ("object" === typeof obj) {
    for (let k in obj) {
      if (keys.includes(k)) {
        //@ts-ignore
        array.push({ id: obj["id"], uri: obj["uri"] });
      } else {
        //@ts-ignore
        allNodes({ obj: obj[k], keys, array });
      }
    }
  }
  return array as string[];
}

const block = {
  fieldGroupName: "TestLayoutTestFlexLayoutHeroArticlesLayout",
  heroRepeater: [
    {
      isHero: true,
      heroArticlePostContainer: [
        {
          heroArticlePostItem: {
            nodes: [
              {
                id: "cG9zdDoyMjQ2NDAw",
                uri: "/2023/08/09/final-word-2024-blackbookers/",
                featuredImage: {
                  node: {
                    mediaDetails: {
                      sizes: [
                        {
                          sourceUrl:
                            "https://scplaybook.com.au/wp-content/uploads/2023/08/060623_leiataua_photosport-1024x585.jpg",
                          __typename: "MediaSize",
                        },
                      ],
                      __typename: "MediaDetails",
                    },
                    slug: "060623_leiataua_photosport",
                    title: "060623_leiataua_photosport",
                    uri: "/2023/08/09/final-word-2024-blackbookers/060623_leiataua_photosport/",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                articleOptions: {
                  byline:
                    "2019 runner-up Walson Carlos takes a look ahead to who you should be targeting for the 2024 NRL SuperCoach season",
                  fieldGroupName: "ArticleOptions",
                  frontPageTitle: "2024 Watch List: Players to keep an eye on",
                  highlight: ["text"],
                  highlightText: "Close watch",
                  __typename: "ArticleOptions",
                },
                categories: {
                  edges: [
                    {
                      node: {
                        id: "dGVybToyODcy",
                        name: "NRL",
                        uri: "/category/nrl/",
                        __typename: "Category",
                      },
                      __typename: "PostToCategoryConnectionEdge",
                    },
                  ],
                  __typename: "PostToCategoryConnection",
                },
                date: "2023-08-09T13:11:25",
                __typename: "Post",
              },
            ],
            __typename: "AcfContentNodeConnection",
          },
          __typename:
            "TestLayoutTestFlexLayoutHeroRepeaterHeroArticlePostContainer",
        },
        {
          heroArticlePostItem: {
            nodes: [
              {
                id: "cG9zdDoyMjU0MDMx",
                uri: "/2024/02/13/draft-rankings-ct-positional-analysis/",
                featuredImage: {
                  node: {
                    mediaDetails: {
                      sizes: [
                        {
                          sourceUrl:
                            "https://scplaybook.com.au/wp-content/uploads/2023/09/Jamayne-Isaako-2.09.23-1024x576.jpg",
                          __typename: "MediaSize",
                        },
                      ],
                      __typename: "MediaDetails",
                    },
                    slug: "jamayne-isaako-2-09-23",
                    title: "Jamayne-Isaako-2.09.23",
                    uri: "/2023/09/04/yearly-wrap-2023-winners-losers-lessons-learnt/jamayne-isaako-2-09-23/",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                articleOptions: {
                  byline:
                    "We dive through the centre-wing positions to rank the best players to pick-up on NRL Supercoach Draft day in 2024.",
                  fieldGroupName: "ArticleOptions",
                  frontPageTitle: "Draft rankings: CTW positional analysis",
                  highlight: ["text"],
                  highlightText: "Draft picks",
                  __typename: "ArticleOptions",
                },
                categories: {
                  edges: [
                    {
                      node: {
                        id: "dGVybToyODcy",
                        name: "NRL",
                        uri: "/category/nrl/",
                        __typename: "Category",
                      },
                      __typename: "PostToCategoryConnectionEdge",
                    },
                  ],
                  __typename: "PostToCategoryConnection",
                },
                date: "2024-02-13T12:54:28",
                __typename: "Post",
              },
            ],
            __typename: "AcfContentNodeConnection",
          },
          __typename:
            "TestLayoutTestFlexLayoutHeroRepeaterHeroArticlePostContainer",
        },
        {
          heroArticlePostItem: {
            nodes: [
              {
                id: "cG9zdDoxOTYxODg0",
                uri: "/2022/06/02/captains-challenge-round-13-skippers-to-target-and-avoid/",
                featuredImage: {
                  node: {
                    mediaDetails: {
                      sizes: [
                        {
                          sourceUrl:
                            "https://scplaybook.com.au/wp-content/uploads/2022/05/Scott-Drinkwater-1024x576.jpeg",
                          __typename: "MediaSize",
                        },
                      ],
                      __typename: "MediaDetails",
                    },
                    slug: "scott-drinkwater",
                    title: "Scott Drinkwater",
                    uri: "/2022/05/02/weekly-wrap-round-8-stars-flops-injuries/scott-drinkwater/",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                articleOptions: {
                  byline:
                    "We run the rule over the best NRL SuperCoach captaincy and vice-captaincy options to consider.",
                  fieldGroupName: "ArticleOptions",
                  frontPageTitle:
                    "Captain's Challenge: Round 13, skippers to target and avoid",
                  highlight: ["text"],
                  highlightText: "Double up!",
                  __typename: "ArticleOptions",
                },
                categories: {
                  edges: [
                    {
                      node: {
                        id: "dGVybToyODcy",
                        name: "NRL",
                        uri: "/category/nrl/",
                        __typename: "Category",
                      },
                      __typename: "PostToCategoryConnectionEdge",
                    },
                    {
                      node: {
                        id: "dGVybToxOA==",
                        name: "Subscriber Only",
                        uri: "/category/subscriber-only/",
                        __typename: "Category",
                      },
                      __typename: "PostToCategoryConnectionEdge",
                    },
                  ],
                  __typename: "PostToCategoryConnection",
                },
                date: "2022-06-02T18:57:54",
                __typename: "Post",
              },
            ],
            __typename: "AcfContentNodeConnection",
          },
          __typename:
            "TestLayoutTestFlexLayoutHeroRepeaterHeroArticlePostContainer",
        },
      ],
      __typename: "TestLayoutTestFlexLayoutHeroRepeater",
    },
  ],
  __typename: "TestLayoutTestFlexLayoutHeroArticlesLayout",
};

const findAllPostIdsFromObject = ({
  //@ts-ignore
  obj,
  goodStrings = ["uri"],
  badStrings = ["/category"],
  array = [],
}): findAllPostIdsFromObjectProps => {
  const nodes = allNodes({ obj, keys: goodStrings, array }) ?? [];
  //@ts-ignore
  return nodes.filter(
    //@ts-ignore
    (node) => !badStrings.some((badStr) => node?.uri.includes(badStr))
  ) as string[];
};

export const findAllPostIdsFromObjects = ({
  objs,
  goodStrings = ["id"],
  badStrings = ["/category"],
  array = [],
}: findAllPostIdsFromObjectsProps) => {
  const arr: string[] = [];
  objs.forEach(function (obj) {
    const theseIds = findAllPostIdsFromObject({
      obj,
      goodStrings,
      badStrings,
      array: [],
    });

    if (theseIds) {
      arr.push(...(theseIds as unknown as string[]));
    }
  });
  return Array.from(new Set(arr));
};

// const nonRepeatableBlocks =
//   //@ts-ignore
//   data?.page?.template?.testLayout?.testFlexLayout.filter((block: object) => {
//     //@ts-ignore
//     return NonRepeatableArticleContainers.includes(block.fieldGroupName);
//   });

// const nonRepeatableIds = findAllPostIdsFromObjects({
//   objs: nonRepeatableBlocks,
//   goodStrings: ["id"],
//   badStrings: ["/category/"],
//   array: [],
// });

// console.log("nonRepeatableIds", nonRepeatableIds);
// const data = {
//     page: {
//       id: "cG9zdDoyMjUxNDQ5",
//       title: "Test Front Page",
//       uri: "/test-front-page/",
//       template: {
//         templateName: "Front-Page",
//         testLayout: {
//           fieldGroupName: "TestLayout",
//           testFlexLayout: [
//             {
//               fieldGroupName: "TestLayoutTestFlexLayoutHeroArticlesLayout",
//               heroRepeater: [
//                 {
//                   isHero: true,
//                   heroArticlePostContainer: [
//                     {
//                       heroArticlePostItem: {
//                         nodes: [
//                           {
//                             id: "cG9zdDoyMzMxODQy",
//                             uri: "/2024/04/23/nrl-supercoach-analysis-round-8-team-lists/",
//                             featuredImage: {
//                               node: {
//                                 mediaDetails: {
//                                   sizes: [
//                                     {
//                                       sourceUrl:
//                                         "https://scplaybook.com.au/wp-content/uploads/2024/04/740dcdfa01b054cef2a51ac66b6ba864-1-1024x576.jpeg",
//                                       __typename: "MediaSize",
//                                     },
//                                   ],
//                                   __typename: "MediaDetails",
//                                 },
//                                 slug: "740dcdfa01b054cef2a51ac66b6ba864-2",
//                                 title: "Nathan Cleary 2024",
//                                 uri: "/2024/04/23/nrl-supercoach-analysis-round-8-team-lists/740dcdfa01b054cef2a51ac66b6ba864-2/",
//                                 __typename: "MediaItem",
//                               },
//                               __typename:
//                                 "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                             },
//                             articleOptions: {
//                               byline:
//                                 "2020 NRL Supercoach runner-up Tim Williams analyses all the major Ins and Outs ahead for Supercoach Round 8.",
//                               fieldGroupName: "ArticleOptions",
//                               frontPageTitle:
//                                 "NRL Supercoach Analysis: Round 8 Team Lists",
//                               highlight: ["category"],
//                               highlightText: null,
//                               __typename: "ArticleOptions",
//                             },
//                             categories: {
//                               edges: [
//                                 {
//                                   node: {
//                                     id: "dGVybToyODcy",
//                                     name: "NRL",
//                                     uri: "/category/nrl/",
//                                     __typename: "Category",
//                                   },
//                                   __typename: "PostToCategoryConnectionEdge",
//                                 },
//                                 {
//                                   node: {
//                                     id: "dGVybToxOA==",
//                                     name: "Subscriber Only",
//                                     uri: "/category/subscriber-only/",
//                                     __typename: "Category",
//                                   },
//                                   __typename: "PostToCategoryConnectionEdge",
//                                 },
//                               ],
//                               __typename: "PostToCategoryConnection",
//                             },
//                             date: "2024-04-23T17:33:09",
//                             __typename: "Post",
//                           },
//                         ],
//                         __typename: "AcfContentNodeConnection",
//                       },
//                       __typename:
//                         "TestLayoutTestFlexLayoutHeroRepeaterHeroArticlePostContainer",
//                     },
//                     {
//                       heroArticlePostItem: {
//                         nodes: [
//                           {
//                             id: "cG9zdDoyMjU0MDMx",
//                             uri: "/2024/02/13/draft-rankings-ct-positional-analysis/",
//                             featuredImage: {
//                               node: {
//                                 mediaDetails: {
//                                   sizes: [
//                                     {
//                                       sourceUrl:
//                                         "https://scplaybook.com.au/wp-content/uploads/2023/09/Jamayne-Isaako-2.09.23-1024x576.jpg",
//                                       __typename: "MediaSize",
//                                     },
//                                   ],
//                                   __typename: "MediaDetails",
//                                 },
//                                 slug: "jamayne-isaako-2-09-23",
//                                 title: "Jamayne-Isaako-2.09.23",
//                                 uri: "/2023/09/04/yearly-wrap-2023-winners-losers-lessons-learnt/jamayne-isaako-2-09-23/",
//                                 __typename: "MediaItem",
//                               },
//                               __typename:
//                                 "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                             },
//                             articleOptions: {
//                               byline:
//                                 "We dive through the centre-wing positions to rank the best players to pick-up on NRL Supercoach Draft day in 2024.",
//                               fieldGroupName: "ArticleOptions",
//                               frontPageTitle:
//                                 "Draft rankings: CTW positional analysis",
//                               highlight: ["text"],
//                               highlightText: "Draft picks",
//                               __typename: "ArticleOptions",
//                             },
//                             categories: {
//                               edges: [
//                                 {
//                                   node: {
//                                     id: "dGVybToyODcy",
//                                     name: "NRL",
//                                     uri: "/category/nrl/",
//                                     __typename: "Category",
//                                   },
//                                   __typename: "PostToCategoryConnectionEdge",
//                                 },
//                               ],
//                               __typename: "PostToCategoryConnection",
//                             },
//                             date: "2024-02-13T12:54:28",
//                             __typename: "Post",
//                           },
//                         ],
//                         __typename: "AcfContentNodeConnection",
//                       },
//                       __typename:
//                         "TestLayoutTestFlexLayoutHeroRepeaterHeroArticlePostContainer",
//                     },
//                     {
//                       heroArticlePostItem: {
//                         nodes: [
//                           {
//                             id: "cG9zdDoxOTYxODg0",
//                             uri: "/2022/06/02/captains-challenge-round-13-skippers-to-target-and-avoid/",
//                             featuredImage: {
//                               node: {
//                                 mediaDetails: {
//                                   sizes: [
//                                     {
//                                       sourceUrl:
//                                         "https://scplaybook.com.au/wp-content/uploads/2022/05/Scott-Drinkwater-1024x576.jpeg",
//                                       __typename: "MediaSize",
//                                     },
//                                   ],
//                                   __typename: "MediaDetails",
//                                 },
//                                 slug: "scott-drinkwater",
//                                 title: "Scott Drinkwater",
//                                 uri: "/2022/05/02/weekly-wrap-round-8-stars-flops-injuries/scott-drinkwater/",
//                                 __typename: "MediaItem",
//                               },
//                               __typename:
//                                 "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                             },
//                             articleOptions: {
//                               byline:
//                                 "We run the rule over the best NRL SuperCoach captaincy and vice-captaincy options to consider.",
//                               fieldGroupName: "ArticleOptions",
//                               frontPageTitle:
//                                 "Captain's Challenge: Round 13, skippers to target and avoid",
//                               highlight: ["text"],
//                               highlightText: "Double up!",
//                               __typename: "ArticleOptions",
//                             },
//                             categories: {
//                               edges: [
//                                 {
//                                   node: {
//                                     id: "dGVybToyODcy",
//                                     name: "NRL",
//                                     uri: "/category/nrl/",
//                                     __typename: "Category",
//                                   },
//                                   __typename: "PostToCategoryConnectionEdge",
//                                 },
//                                 {
//                                   node: {
//                                     id: "dGVybToxOA==",
//                                     name: "Subscriber Only",
//                                     uri: "/category/subscriber-only/",
//                                     __typename: "Category",
//                                   },
//                                   __typename: "PostToCategoryConnectionEdge",
//                                 },
//                               ],
//                               __typename: "PostToCategoryConnection",
//                             },
//                             date: "2022-06-02T18:57:54",
//                             __typename: "Post",
//                           },
//                         ],
//                         __typename: "AcfContentNodeConnection",
//                       },
//                       __typename:
//                         "TestLayoutTestFlexLayoutHeroRepeaterHeroArticlePostContainer",
//                     },
//                   ],
//                   __typename: "TestLayoutTestFlexLayoutHeroRepeater",
//                 },
//               ],
//               __typename: "TestLayoutTestFlexLayoutHeroArticlesLayout",
//             },
//             {
//               fieldGroupName: "TestLayoutTestFlexLayoutFrontPageCategoriesLayout",
//               categoriesHeading: "Pre Season",
//               categoriesShowTabs: true,
//               frontPageCategory: {
//                 nodes: [
//                   {
//                     id: "dGVybToyODY0",
//                     name: "Bank Builder",
//                     slug: "bank-builder",
//                     posts: {
//                       nodes: [
//                         {
//                           id: "cG9zdDoxOTUzNjgy",
//                           articleOptions: {
//                             byline:
//                               "Stat man Maty Broom takes a deep dive into the key numbers to consider when making your Round 11 trades.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Stat Breakdown: Key numbers shaping Round 11",
//                             highlight: ["text"],
//                             highlightText: "Critical thinking",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2022/01/Hilton-Cartwright-1200x778.jpeg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "hilton-cartwright",
//                               title: "Hilton Cartwright",
//                               uri: "/2022/01/11/stat-breakdown-key-numbers-shaping-round-11/hilton-cartwright/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyODY0",
//                                   name: "Bank Builder",
//                                   uri: "/category/bank-builder/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title: "Stat Breakdown: Key numbers shaping Round 11",
//                           slug: "stat-breakdown-key-numbers-shaping-round-11",
//                           uri: "/2022/01/11/stat-breakdown-key-numbers-shaping-round-11/",
//                           date: "2022-01-11T09:34:22",
//                           __typename: "Post",
//                         },
//                         {
//                           id: "cG9zdDoxOTUzNTg2",
//                           articleOptions: {
//                             byline:
//                               "Stat man Maty Broom takes a deep dive into the key numbers to consider when making your Round 9 trades.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Stat Breakdown: Key numbers shaping Round 9",
//                             highlight: ["text"],
//                             highlightText: "Critical thinking",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2021/11/Kane-Richardson-1200x779.jpg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "kane-richardson-2",
//                               title: "Kane Richardson",
//                               uri: "/2021/11/16/squad-breakdown-renegades-player-by-player-analysis/kane-richardson-2/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyODY0",
//                                   name: "Bank Builder",
//                                   uri: "/category/bank-builder/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title: "Stat Breakdown: Key numbers shaping Round 9",
//                           slug: "stat-breakdown-key-numbers-shaping-round-9",
//                           uri: "/2022/01/04/stat-breakdown-key-numbers-shaping-round-9/",
//                           date: "2022-01-04T18:06:36",
//                           __typename: "Post",
//                         },
//                         {
//                           id: "cG9zdDoxOTUzNTM4",
//                           articleOptions: {
//                             byline:
//                               "Stat man Maty Broom takes a deep dive into the key numbers to consider when making your Round 8 trades.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Stat Breakdown: Key numbers shaping Round 8",
//                             highlight: ["text"],
//                             highlightText: "Critical thinking",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: null,
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "joe-clarke",
//                               title: "Joe Clarke",
//                               uri: "/2022/01/02/stat-breakdown-key-numbers-shaping-round-8/joe-clarke/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyODY0",
//                                   name: "Bank Builder",
//                                   uri: "/category/bank-builder/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title: "Stat Breakdown: Key numbers shaping Round 8",
//                           slug: "stat-breakdown-key-numbers-shaping-round-8",
//                           uri: "/2022/01/02/stat-breakdown-key-numbers-shaping-round-8/",
//                           date: "2022-01-02T08:54:13",
//                           __typename: "Post",
//                         },
//                         {
//                           id: "cG9zdDoxOTUzMzc2",
//                           articleOptions: {
//                             byline:
//                               "Stat man Maty Broom takes a deep dive into the key numbers to consider when making your Round 4 trades.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Stat Breakdown: Key numbers shaping Round 4",
//                             highlight: ["text"],
//                             highlightText: "Critical thinking",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2021/12/AJ-Tye-1200x676.jpeg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "bbl-scorchers-v-sixers-2",
//                               title: "BBL – Scorchers v Sixers",
//                               uri: "/2021/12/17/stat-breakdown-key-numbers-shaping-round-4/bbl-scorchers-v-sixers-2/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyODY0",
//                                   name: "Bank Builder",
//                                   uri: "/category/bank-builder/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                               {
//                                 node: {
//                                   id: "dGVybToxOA==",
//                                   name: "Subscriber Only",
//                                   uri: "/category/subscriber-only/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title: "Stat Breakdown: Key numbers shaping Round 4",
//                           slug: "stat-breakdown-key-numbers-shaping-round-4",
//                           uri: "/2021/12/17/stat-breakdown-key-numbers-shaping-round-4/",
//                           date: "2021-12-17T12:12:20",
//                           __typename: "Post",
//                         },
//                         {
//                           id: "cG9zdDoxOTUzMzAy",
//                           articleOptions: {
//                             byline:
//                               "Stat man Maty Broom takes a deep dive into the key numbers to consider when making your Round 3 trades.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Stat Breakdown: Key numbers shaping Round 3",
//                             highlight: ["text"],
//                             highlightText: "Critical thinking",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2021/12/Ashton-Agar-1200x778.jpeg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "bbl-brisbane-heat-v-perth-scorchers-2",
//                               title: "BBL – Brisbane Heat v Perth Scorchers",
//                               uri: "/2021/12/11/stat-breakdown-key-numbers-shaping-round-3/bbl-brisbane-heat-v-perth-scorchers-2/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyODY0",
//                                   name: "Bank Builder",
//                                   uri: "/category/bank-builder/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title: "Stat Breakdown: Key numbers shaping Round 3",
//                           slug: "stat-breakdown-key-numbers-shaping-round-3",
//                           uri: "/2021/12/11/stat-breakdown-key-numbers-shaping-round-3/",
//                           date: "2021-12-11T10:50:28",
//                           __typename: "Post",
//                         },
//                       ],
//                       __typename: "CategoryToPostConnection",
//                     },
//                     __typename: "Category",
//                   },
//                   {
//                     id: "dGVybToyNA==",
//                     name: "Draft Breakdown",
//                     slug: "draft-breakdown-2",
//                     posts: {
//                       nodes: [
//                         {
//                           id: "cG9zdDoxOTU2MDIz",
//                           articleOptions: {
//                             byline:
//                               "We take a look at the best options to grab from the NRL SuperCoach Draft wires in Round 4.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Draft Breakdown: Round 4, waiver-wire targets",
//                             highlight: ["text"],
//                             highlightText: "Draft targets",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2022/03/Kenny-Bromwich-1200x685.jpg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "kenny-bromwich-4",
//                               title: "Kenny Bromwich",
//                               uri: "/2022/03/29/draft-breakdown-round-4-waiver-wire-targets/kenny-bromwich-4/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyNA==",
//                                   name: "Draft Breakdown",
//                                   uri: "/category/draft-breakdown-2/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                               {
//                                 node: {
//                                   id: "dGVybToyODcy",
//                                   name: "NRL",
//                                   uri: "/category/nrl/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title: "Draft Breakdown: Round 4, waiver-wire targets",
//                           slug: "draft-breakdown-round-4-waiver-wire-targets",
//                           uri: "/2022/03/29/draft-breakdown-round-4-waiver-wire-targets/",
//                           date: "2022-03-29T12:42:18",
//                           __typename: "Post",
//                         },
//                         {
//                           id: "cG9zdDoxOTU1ODQ4",
//                           articleOptions: {
//                             byline:
//                               "We take a look at the best options to grab from the NRL SuperCoach Draft wires in Round 3.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Draft Breakdown: Round 3, waiver-wire targets",
//                             highlight: ["text"],
//                             highlightText: "Draft targets",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2022/03/Tom-Starling-1200x675.jpeg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "tom-starling-2",
//                               title: "Tom Starling",
//                               uri: "/2022/03/22/draft-breakdown-round-3-waiver-wire-targets/tom-starling-2/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyNA==",
//                                   name: "Draft Breakdown",
//                                   uri: "/category/draft-breakdown-2/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                               {
//                                 node: {
//                                   id: "dGVybToyODcy",
//                                   name: "NRL",
//                                   uri: "/category/nrl/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title: "Draft Breakdown: Round 3, waiver-wire targets",
//                           slug: "draft-breakdown-round-3-waiver-wire-targets",
//                           uri: "/2022/03/22/draft-breakdown-round-3-waiver-wire-targets/",
//                           date: "2022-03-22T09:35:02",
//                           __typename: "Post",
//                         },
//                         {
//                           id: "cG9zdDoxOTU1Njg4",
//                           articleOptions: {
//                             byline:
//                               "We take a look at the best options to grab from the NRL SuperCoach Draft wires in Round 2.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Draft Breakdown: Round 2, waiver-wire targets",
//                             highlight: ["text"],
//                             highlightText: "Draft targets",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2022/03/Jeremiah-Nanai-1200x836.jpeg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "nrl-rd-22-cowboys-v-wests-tigers",
//                               title: "NRL Rd 22 – Cowboys v Wests Tigers",
//                               uri: "/2022/03/15/draft-breakdown-round-2-waiver-wire-targets/nrl-rd-22-cowboys-v-wests-tigers/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyNA==",
//                                   name: "Draft Breakdown",
//                                   uri: "/category/draft-breakdown-2/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                               {
//                                 node: {
//                                   id: "dGVybToyODcy",
//                                   name: "NRL",
//                                   uri: "/category/nrl/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                               {
//                                 node: {
//                                   id: "dGVybToxOA==",
//                                   name: "Subscriber Only",
//                                   uri: "/category/subscriber-only/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title: "Draft Breakdown: Round 2, waiver-wire targets",
//                           slug: "draft-breakdown-round-2-waiver-wire-targets",
//                           uri: "/2022/03/15/draft-breakdown-round-2-waiver-wire-targets/",
//                           date: "2022-03-15T10:31:25",
//                           __typename: "Post",
//                         },
//                         {
//                           id: "cG9zdDoxOTUyNTMz",
//                           articleOptions: {
//                             byline:
//                               "We take a look at the best options to grab from the SuperCoach Draft wires ahead of Round 25, grand final week.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Draft Breakdown: Waiver-wire targets, grand final edition",
//                             highlight: ["text"],
//                             highlightText: "Draft targets",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2021/08/Albert-Kelly-1200x675.jpeg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "albert-kelly",
//                               title: "Albert Kelly",
//                               uri: "/2021/08/31/draft-breakdown-waiver-wire-targets-grand-final-edition/albert-kelly/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyNA==",
//                                   name: "Draft Breakdown",
//                                   uri: "/category/draft-breakdown-2/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title:
//                             "Draft Breakdown: Waiver-wire targets, grand final edition",
//                           slug: "draft-breakdown-waiver-wire-targets-grand-final-edition",
//                           uri: "/2021/08/31/draft-breakdown-waiver-wire-targets-grand-final-edition/",
//                           date: "2021-08-31T13:32:12",
//                           __typename: "Post",
//                         },
//                         {
//                           id: "cG9zdDoxOTUyNDEw",
//                           articleOptions: {
//                             byline:
//                               "We take a look at the best options to grab from the SuperCoach Draft wires ahead of Round 24, finals week three.",
//                             fieldGroupName: "ArticleOptions",
//                             frontPageTitle:
//                               "Draft Breakdown: Waiver-wire targets, finals week three",
//                             highlight: ["text"],
//                             highlightText: "Draft targets",
//                             __typename: "ArticleOptions",
//                           },
//                           featuredImage: {
//                             node: {
//                               mediaDetails: {
//                                 sizes: [
//                                   {
//                                     sourceUrl:
//                                       "https://scplaybook.com.au/wp-content/uploads/2021/08/Braydon-Trindall-1200x675.jpeg",
//                                     __typename: "MediaSize",
//                                   },
//                                 ],
//                                 __typename: "MediaDetails",
//                               },
//                               slug: "braydon-trindall",
//                               title: "Braydon Trindall",
//                               uri: "/2021/08/05/scouting-mission-getting-an-early-jump-on-supercoach-2022/braydon-trindall/",
//                               __typename: "MediaItem",
//                             },
//                             __typename:
//                               "NodeWithFeaturedImageToMediaItemConnectionEdge",
//                           },
//                           categories: {
//                             edges: [
//                               {
//                                 node: {
//                                   id: "dGVybToyNA==",
//                                   name: "Draft Breakdown",
//                                   uri: "/category/draft-breakdown-2/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                               {
//                                 node: {
//                                   id: "dGVybToxOA==",
//                                   name: "Subscriber Only",
//                                   uri: "/category/subscriber-only/",
//                                   __typename: "Category",
//                                 },
//                                 __typename: "PostToCategoryConnectionEdge",
//                               },
//                             ],
//                             __typename: "PostToCategoryConnection",
//                           },
//                           title:
//                             "Draft Breakdown: Waiver-wire targets, finals week three",
//                           slug: "draft-breakdown-waiver-wire-targets-finals-week-three",
//                           uri: "/2021/08/24/draft-breakdown-waiver-wire-targets-finals-week-three/",
//                           date: "2021-08-24T13:49:59",
//                           __typename: "Post",
//                         },
//                       ],
//                       __typename: "CategoryToPostConnection",
//                     },
//                     __typename: "Category",
//                   },
//                 ],
//                 __typename: "AcfTermNodeConnection",
//               },
//               __typename: "TestLayoutTestFlexLayoutFrontPageCategoriesLayout",
//             },
//             {
//               fieldGroupName: "TestLayoutTestFlexLayoutFrontPageVideosLayout",
//               frontPageVideosHeader: "Videos",
//               __typename: "TestLayoutTestFlexLayoutFrontPageVideosLayout",
//             },
//             {
//               fieldGroupName: "TestLayoutTestFlexLayoutBlokeVideosLayout",
//               __typename: "TestLayoutTestFlexLayoutBlokeVideosLayout",
//             },
//             {
//               fieldGroupName: "TestLayoutTestFlexLayoutFrontPagePodcastLayout",
//               podcastEmbedLink:
//                 "https://embed.podcasts.apple.com/au/podcast/sc-playbook/id1496277296",
//               __typename: "TestLayoutTestFlexLayoutFrontPagePodcastLayout",
//             },
//           ],
//           __typename: "TestLayout",
//         },
//         __typename: "Template_FrontPage",
//       },
//       __typename: "Page",
//     },
//   };
